export const colorPalette = {
  Bills: "#FFA8BD",
  Home: "#ff80bd",
  Entertainment: "#ff5cde",
  Groceries: "#dc42ff",
  Restaurants: "#a238ff",
  Food: "#7429ff",
  Insurence: "#4f1fff",
  Communication: "#3014ff",
  Transportation: "#0d05ff",
  Energy: "#0008f5",
  Other: "#9AA0A8",

  January: "#FFA8BD",
  February: "#ff80bd",
  March: "#ff5cde",
  April: "#dc42ff",
  May: "#a238ff",
  June: "#7429ff",
  July: "#4f1fff",
  August: "#3014ff",
  September: "#0d05ff",
  October: "#0008f5",
  November: "#5230A7",
  December: "#884B72",

  2023: "#FFA8BD",
  2022: "#ff80bd",
  2021: "#ff5cde",
  2020: "#dc42ff",
  2019: "#a238ff",
  2018: "#7429ff",
  2017: "#4f1fff",
};
